import "./App.css";
import SearchField from "react-search-field";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import axios from "axios";
import cloud from './Clouds.mp4'


function App() {

  const[city,setCity]=useState('')
  const [temp,setTemp]=useState(0)
  const [humidity,setHumidity]=useState(0)
  const [search,setSearch]=useState("delhi")
  const [pressure,setPressure]=useState(0)
  const [wind,setWind]=useState(0)

  const [dark,setDark]=useState(true)

  function handlesubmit (){ 
         setDark(!dark)    
  }

  useEffect(()=>{
    axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${search}&appid=2f75986e8e685feb6881d40f3c5648db`).then((res)=>{
      console.log("res",res)
      setTemp(res.data.main.temp);
      setPressure(res.data.main.pressure);
      setHumidity(res.data.main.humidity);
      setWind(res.data.wind.speed);
      setCity(res.data.name);
    })
  },[search])

  return (
    <>
    

      
<video className='videoTag'    autoPlay loop muted>                        
    <source src={cloud}  type='video/mp4' />    
</video>

      <div className="weather-box" style={{ backgroundColor: dark?'':'black'}} >
        <div style={{display:'flex',alignItems:'center',justifyContent:'end',margin:"1.5rem"}}>
        <FeatherIcon  style={{color:'white',width:'1.2rem'}} className="sun" icon='sun'/>
      
        
        <input type="checkbox" onClick={handlesubmit} id="tooglenight" className="cbx hidden"/>
        <label htmlFor="tooglenight" className="switch"></label>
        
        
        <FeatherIcon style={{color:'white',width:'1.2rem'}}  className="moon" icon='moon'/>
        </div>
        <div className="weather-header">
          <h1>Weather Web</h1>
         

          {/* <button onClick={handlesubmit} >Dark Mode</button> */}
        </div>
        <div className="weather-search">
          <SearchField autoFocus="true"
            placeholder="Search city..." 
            searchText=""
            classNames="weather-input"
            onSearchClick={(value)=>{setSearch(value)}}
          />
        </div>
        <div className="weather-cloud">
          <FeatherIcon icon="cloud-rain" color="white" size="40" />
        </div>
        <div className="weather-city">
          <h1>{city}</h1>
        </div>
        <div className="weather-row">
          <div className="weather-column">
            <FeatherIcon icon="sun" color="white" size="40" />
            <p>Temp : {(temp-273.15).toFixed(2)}°C</p>
          </div>
          <div className="weather-column">
              <FeatherIcon icon="command" color="white" size="40" />
              <p>Pressure : {pressure}Pascal</p>
            </div>
        </div>
        <div className="weather-row">
          <div className="weather-column">
            <FeatherIcon icon="cloud" color="white" size="40" />
            <p>Humindity : {humidity}%</p>
          </div>
          <div className="weather-column">
              <FeatherIcon icon="wind" color="white" size="40" />
              <p>Wind : {(wind*(18/5)).toFixed(2)}km/h</p>
            </div>
            
        </div>
        <div>
               <h6 id="lasth" style={{display:'flex',alignItems:'end',justifyContent:'flex-end',color:'white'}}>Developed by jd</h6>
    
            </div>
      </div>
     
    </>
  );
}

export default App;
